import { IonButton, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import './SoulboundPage.css';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as ethers from "ethers";
import Topbar from '../components/TopBar';
import truncateEthAddress from "truncate-eth-address";
import { CopyButton } from "@web3uikit/core";
import { refresh } from '../features/counter/profileSlice';
import  getContract  from './helpers';
import tokenMeta from "../abi/TokenMetadata.json"

const SoulBoundPage: React.FC = () => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const dispatch = useDispatch();
  const { name } = useParams<{ name: string; }>();
  const pk = useSelector((state: RootState) => state.profile.pk)
  const pubk = useSelector((state: RootState) => state.profile.pubk)
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  const update = useSelector((state: RootState) => state.profile.refresh)
  const profile = useSelector((state: RootState) => state.profile)
  const addressBook = useSelector((state: RootState) => state.profile?.contractData?.metaContract?.addressBook) //"0x51332C7fFbeb3394E57A138A057c0D4Efc2c7d12";//
  const [nfts, setNfts] = useState<any>([]);
  const redux = useSelector((state: RootState) => state)
  const [mytokenCount, setMyTokenCount] = useState(1);
  
  const state = useSelector((state: RootState) => state)

 
  //let update = 0;
  useEffect(() => {
    // console.log("addressBook ", addressBook)
    // const provider = useSelector((state: RootState) => state.counter.provider)
    // provider.

    (async () => {

      const book = await getContract(addressBook, "SoulboundToken.json",state)
      let n:any = []
      let allSBTokens = parseInt((await book.totalSupply()).toString());
      const mytoken  = parseInt((await book.balanceOf(pubk)).toString());
      setMyTokenCount(mytoken)
      console.log("allSBTokens ", allSBTokens)
      for(let x:number =  0; x<allSBTokens;x++){
        const r:any = await book.getTokenURI(x);
        n.push(r)
        setNfts(Object.assign([],n))
      }
    
      //const abi = sbabi.output.abi;
      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const signer = new ethers.Wallet(pk, provider)
      const nft = new ethers.Contract(profile.contractData.metaContract.collection, tokenMeta.abi, signer); //0xaCD477E7d64f0493B07F13C2C104353C5fcc3EE6
      //console.log(await nft.)
      //console.log(await provider.getCode(groups.groups[x].external_id))
      // nftContractReadonly.safeMint(pubk,"ipfs://QmQvpBK5PwBopucRBDWE8w7CoNg7MMJenSigN85LmwDyRG")
      const address = pubk;//"0x47D4eafb1429b8f30842E1E47061Ad3EeC94141E"
      const nft_balance = await nft.balanceOf(address)



      for (let y = 0; y < nft_balance; y++) {
        const token_id = await nft.tokenOfOwnerByIndex(address, y)
        const tokenURI = await nft.tokenURI(token_id)
        //let metadata: any = {}
        // try {
        //   try {
            let metadata = await fetchIpfs(tokenURI)
        //   } catch (e) {

        //   }

        //   const json: any = metadata;//await metadata.json()
        //   console.log(json)
        //   json.token_id = token_id.toString();
        //   json.group = groups.groups[x]; //collection
          // json.name = ""
          // json.image =""
          //todo????
          n.push(metadata)
          //console.log("loading")
          setNfts(JSON.parse(JSON.stringify(n)))


        // } catch (e) {
        //   console.log(e)
        // }
      }

      //setNfts()

    })();

  }, [update])
  //old 0xAbA8905703c2405F3502f2aEc5109872753Ad760
  const ipfsGateway: string = "https://files.xlapp.link/ipfs/";
  const fetchIpfs = async (uri: string) => {
    const metadata = await fetch(uri.replace("ipfs://", ipfsGateway));//"https://cloudflare-ipfs.com/ipfs/"));
    let meta = await metadata.json()
    meta.url = meta.url.replace("ipfs://", ipfsGateway)//"https://cloudflare-ipfs.com/ipfs/")
    return meta;
  }
  const mintsbt = async () => {
    const book = await getContract(addressBook, "SoulboundToken.json")
    const r = await book.safeMint(pubk,redux.profile.profile.name, redux.profile.profile.email,redux.profile.profile.avatar)
    console.log("r ", r)
    //update = parseInt((await book.totalSupply()).toString());
    dispatch(refresh)
    return;

    // const  tokenData =  Object.assign({}, {
    //   "name": redux.profile.profile.name,
    //   "email": redux.profile.profile.email,
    //   "avatar": redux.profile.profile.avatar,
    //   "address":pubk

    // });

    // console.log("props ", Object.assign({}, {
    //   "name": redux.profile.profile.name,
    //   "email": redux.profile.profile.email,
    //   "avatar": redux.profile.profile.avatar,
    //   "address":pubk

    // }))
    //return;
  

/*
    const abi = sbabi.output.abi;
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    //const signer = ethers.Wallet.fromMnemonic(myAccountMnemonic);
    const signer = new ethers.Wallet(pk, provider)

    const nftContractReadonly = new ethers.Contract("0xd9145CCE52D386f254917e481eB44e9943F39138", abi, signer);
    //nftContractReadonly.filters.Transfer()
    //const nftContract:any = nftContractReadonly.connect(signer);
    //let account = signer.address;//(await signer.getAddress())[0]

    nftContractReadonly.safeMint(pubk, "ipfs://QmQvpBK5PwBopucRBDWE8w7CoNg7MMJenSigN85LmwDyRG")
    //alert(account)
    // Transfer
    //nftContract.transferFrom(account, targetAccount, tokenId);
    */
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <Topbar/>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {mytokenCount==0?<IonButton onClick={mintsbt}>Mint My Soulbound Token</IonButton>:null}
        <br />
        {/* You must first KYC Verify */}
        {/* 0x94c1500D9aD7a194D518eB5d0522f4E064287fDe soulbound address */}

        <div className='flex-container-nft'>
          {nfts.map((i: any, k: any) => {
            console.log(i)
            //return <div key={k}>hi</div>
            //console.log(JSON.parse(atob(i.split(",")[1])))
            try{
              i = JSON.parse(atob(i.split(",")[1]));
            }catch(e){
              return null;
            }
           
            return (
              // <>
              <div className='card' key={k}>
                <img className='nft-img'  crossOrigin='anonymous' referrerPolicy="no-referrer" alt={i.name} src={i.avatar} />
            
                {i.name}
                
                <br />
                {/* {i.collection.name} #{i.token_id} */}
                <IonItem>
                {i.email}&nbsp;
                <CopyButton text={pubk} />
                {truncateEthAddress(i.address)}
                  {/* <IonIcon icon={flame} onClick={() => { burn(i, k) }} />
                  <IonIcon icon={send} onClick={() => { sendNft(i, k) }} slot="end" /> */}
                </IonItem>
              </div>

            )
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SoulBoundPage;




