import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useHistory, } from 'react-router';
import { useParams } from 'react-router';
import Menu from './components/Menu';
import Page from './pages/Page';
import ItemsPage from './pages/ItemsPage'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import LoginPage from './pages/LoginPage';

import { useDispatch, useSelector } from 'react-redux';
import Cash from './pages/Cash';
import Deploy from './pages/Deploy';
import DeployItemPage from './pages/DeployItemPage';
import SoulboundPage from './pages/SoulboundPage';
import RevsharePage from './pages/RevsharePage';
import NftsPage from './pages/NftsPage';
import AnalyticsPage from './pages/AnalyticsPage';
import BulkUploadPage from './pages/BulkUploadPage';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import { RootState } from './store';
import registry from './abi/registry.json';
import TokenMetadata from './abi/TokenMetadata.json';
import { contractData, dropsData, refresh, token, pubk, pk, keys, mnemonic } from './features/counter/profileSlice'
import { getKeyPairFromMnemonic } from 'human-crypto-keys';
import { entropyToMnemonic } from 'bip39-web-crypto';

import { whitelistUrl } from "@toruslabs/openlogin"
// import { unescape } from 'querystring';
var md5 = require('md5');


setupIonicReact();
// const store = configureStore({ reducer: {} })


const App: React.FC = () => {
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  const spubk = useSelector((state: RootState) => state.profile.pubk)
  const dispatch = useDispatch()
  //const history = useHistory();
  // const { url } = useParams<{ url: string; }>();
  // const history = useHistory();
  
  useEffect(() => {
   // console.log(history);
    //setTimeout(()=>{history.push("/test");},1000)
//  console.log(history)
    (async () => {
     
      const domain_path = window.location.protocol + "//" + window.location.host
      window.project_id = "205984"
      window.top_project_id = "205984"


      let sig = await whitelistUrl("BJix88SAY8ohaAiin3tPHO6JVfy6XolRCS-tSPjWXZVumPCb-cBAH9vIOVWXIx5dP-xjl2obrdRcxvLxGHmwi-w", "135069d8eee9c4b7dd6b9e16399a6d25b554a278b369c63b205c53c19c04b5a2", domain_path)

      if ((window.location.href.indexOf("result") > 0) && window.location.href.indexOf("/auth/login/") == -1) {
       
        //window.location.assign(window.location.protocol + "//" + window.location.host + "/auth/login/" + window.location.search + ""+window.location.hash);
        console.log("url: ", JSON.parse(atob(window.location.href.split("&")[0].split("=")[1])))
        let r = JSON.parse(atob(window.location.href.split("&")[0].split("=")[1]))
        //let oAuthIdToken = JSON.parse(atob(result.store.oAuthIdToken))
        console.log(r.store.oAuthIdToken.split(".")[1])
        //dispatch(token(result.store.oAuthIdToken.split["."][1]))
        dispatch(token(r.store.oAuthIdToken))
        let x = JSON.parse(atob(window.location.href.split("&")[0].split("=")[1]))
        //x.store.oAuthIdToken = JSON.parse(atob(result.store.oAuthIdToken)
        dispatch(keys(x))
        dispatch(pk(r.ed25519PrivKey))
        let m = await entropyToMnemonic(r.coreKitKey);

        dispatch(mnemonic(m))
        const signer = ethers.Wallet.fromMnemonic(m);
        dispatch(pk(signer.privateKey))
        dispatch(pubk(await signer.getAddress()))

        console.log(r.privKey.toString())
        //console.log("entropyToMnemonic ",await entropyToMnemonic(md5(result.ed25519PrivKey.toString()) + md5(result.ed25519PrivKey.toString())))//btoa(result.ed25519PrivKey.toString())
        console.log("entropyToMnemonic ", await entropyToMnemonic(r.privKey))//btoa(result.ed25519PrivKey.toString())
       // history.push("/")
        // let keyPair: any = await getKeyPairFromMnemonic(await entropyToMnemonic(result.privKey.toString()), { id: 'rsa', modulusLength: 4096 }, { privateKeyFormat: 'pkcs8-der' })
        // console.log("keyPair ", keyPair)
        //let jwk: any = await pkcs8ToJwk(keyPair.privateKey)
      }
      if (window.location.href.indexOf("login") == -1 && window.location.href.indexOf("token") > 0 && window.location.href.indexOf("/auth/login/") == -1) {
        window.location.assign(window.location.protocol + "//" + window.location.host + "/auth/login/" + window.location.search + "" + window.location.hash);
        return;

      }
      // const configCSV = "https://docs.google.com/spreadsheets/d/e/2PACX-1vT-ieNhiOBaheuhoyma93tYd2pY6Ylzc_c-8z4-RjjrxLY8ZKFKKVZ9hBfHT256aizqsyHsth0HAahi/pub?output=csv"
      // const csv:any = await axios.get(configCSV)
      // console.log(csv)
      // const json = await csvtojson().fromString(csv.data)
      // console.log(json)
      // console.log(window.location.hostname)
      // const item:any = json.find(i=>i.domain===window.location.hostname)
      // console.log(item.id)
      // window.project_id = item.id;
      // window.config = item;


      const provider = new ethers.providers.JsonRpcProvider(rpc);
      //const signer = new ethers.Wallet(pk, provider)
      //registry
      const regContract = new ethers.Contract("0x19A3f98aBeEc1626161ECC179881D1BbB575CEeF", registry.abi, provider);
      let result: any;
      try {
        result = await regContract.getAddressByName(window.location.protocol + "//" + window.location.hostname)//.split(".")[0]);//window.location.hostname)


        console.log(result);
      } catch (e) {
        console.log(e)
      }
  
      if (false && result?.hostname === "") {
        window.project_id = "205984";
        let list: any = await regContract.getList()
        let newlist = []
        for (let x = 0; x < list.length; x++) {
          try {
            const tokenContract = new ethers.Contract(list[x].collection, TokenMetadata.abi, provider);
            const t: any = await tokenContract.contractURI()
            console.log("contract uri: ", t);
            const cd: any = (await axios.get(t.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/"))).data
            console.log(cd)
            //list[x].data = cd.contract;//JSON.parse(cd);
            if (cd.metaContract) {
              newlist.push(cd.metaContract)
            }
          } catch (e) {
            console.log(e)
            //list = list.splice(x)
          }
        }
        console.log("newlist ", newlist)
        dispatch(dropsData(newlist))
        dispatch(refresh())
      } else {
        // const tokenContract = new ethers.Contract(result.collection, TokenMetadata.abi, provider);
        // const t: any = await tokenContract.contractURI()
        // console.log("contract uir: ", t);
        // const cd: any = (await axios.get(t.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/"))).data
        // console.log(cd)
        // window.project_id = "205984";//cd.metaContract.project_id
        // dispatch(contractData(cd))
        // dispatch(refresh())
      }





      // let ipfs = await IPFS.create()
      // let file:any = await ipfs.get(t)
      // console.log((await axios.get(t.replace("ipfs://","https://ipfs.io/ipfs/"))).data)
      // console.log(await file.next())
      // await ipfs.stop()

    })();
  }, [])
  //const history = useHistory();
  // const {url} =  useParams<{ url: string; }>();

  // const loginClick() = () =>{
  //   history.push()
  // }
  const getRedirect = () => {
    //return "http://aol.com"
    //window.location.assign(url);
    return "";
  }
  return (

    <>
      {/* <MoralisProvider appId={"MORALIS_APP_ID"} serverUrl={"MORALIS_SERVER_URL"} > */}
      <IonApp>
        {/* <IonHeader> */}
        {/* <IonToolbar> */}
        {/* <IonButtons slot="start"> */}

        {/* </IonButtons> */}
        {/* </IonToolbar> */}
        {/* </IonHeader> */}

        <IonReactRouter>
          <IonSplitPane contentId="main">
          {window.location.hostname !=="localhost" || window.location.host.split(".").length === 3?<Menu />: <div className='logo' style={{height:50, width:150}}><img width={100} height={50} src='/assets/xla.svg' /></div>}
            {/* {spubk.length ?<></> :  } */}
            {/* {pubk.length? <Menu /> :
                window.location.href.indexOf("login")<0?
                
               null//<IonButton routerLink='/auth/login'>Login</IonButton>
                
               :null
         } */}

            <IonRouterOutlet id="main">
              {/* {pubk.length? <Menu /> //:
              
               :null
         } */}
              <Route path="/" exact={true}>
                {/* <Redirect to="/auth/login" /> */}
                <Page />
              </Route>
              <Route path='/redirect' component={() => {
                //window.location.href
                //let destination = window.location.href.replace(window.location.origin, "").replace("/redirect/?", "");
                let destination = unescape(window.location.href.split("?")[1])
                //alert("destination" + destination)
                console.log(destination)
                window.location.replace(destination)

                return null
              }} />

              {/* <Route path="/redirect/:url" exact={true}>
                <>
                  {
                    console.log("hi")
                  }
                </>
                {/* {getRedirect()} */}
              {/* <Redirect to={{ pathname: getRedirect() }} /> */}
              {/* <Redirect to="/auth/login" /> */}
              {/* <Page /> */}
              {/* </Route> */}
              <Route path="/page/cash" exact={true}>
                <Cash />
              </Route>
              <Route path="/page/nfts" exact={true}>
                <NftsPage />
              </Route>
              <Route path="/auth/login" exact={true}>
                <LoginPage />
              </Route>
              <Route path="/page/items" exact={true}>
                <ItemsPage />
              </Route>
              <Route path="/page/store" exact={true}>
                <Page />
              </Route>
              <Route path="/page/deploy" exact={true}>
                <Deploy />
              </Route>
              <Route path="/page/revshare" exact={true}>
                <RevsharePage />
              </Route>
              <Route path="/page/soulbound" exact={true}>
                <SoulboundPage />
              </Route>
              <Route path="/page/analytics" exact={true}>
                <AnalyticsPage />
              </Route>
              <Route path="/page/deploy/:sku" exact={true}>
                <DeployItemPage />
              </Route>
              {/* <Route path="/page/deploy/:sku" exact={true} render= {routeProps =><DeployItem {...routeProps} key={document.location.href} />}>
            
            </Route> */}
              <Route path="/page/messages" exact={true}>
                {/* <iframe title='Community' height={"100%"} frameBorder="0" width={"100%"} src="https://app.commonground.cg/community/P0mI8SYrpObFpyXN1v"></iframe> */}
              </Route>
              <Route path="/page/bulkupload" exact={true}>
                <BulkUploadPage />
                {/* <iframe title='Community' height={"100%"} frameBorder="0" width={"100%"} src="https://app.commonground.cg/community/P0mI8SYrpObFpyXN1v"></iframe> */}
              </Route>

              {/* <Route path="/page/:name" exact={true}>
              <Page />
            </Route> */}
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
      {/* </MoralisProvider> */}
    </>

  );
};

export default App;
//RESOURCE LINKS
//https://ipfs.github.io/public-gateway-checker/
//https://docs.openzeppelin.com/defender/guide-metatx
//https://www.finder.com/cross-chain-nfts
//https://ethereum.stackexchange.com/questions/110924/how-to-properly-implement-a-contracturi-for-on-chain-nfts
//https://web3.storage/docs/how-tos/list-directory-contents/
//https://www.web3.university/tracks/build-your-first-nft/how-to-mint-an-nft-using-ethers-js

//EXAMPLE LANDIGNS
//https://www.defendnature.com/
//https://demons.world/