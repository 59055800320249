import { useCallback, useState } from "react";
import * as ethers from "ethers";
// import { useSelector } from 'react-redux';
// import { RootState } from '../store';

// export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
//   const [translate, setTranslate] = useState(defaultTranslate);
//   const [dimensions, setDimensions] = useState();
//   const containerRef = useCallback((containerElem) => {
//     if (containerElem !== null) {
//       const { width, height } = containerElem.getBoundingClientRect();
//       setDimensions({ width, height });
//       setTranslate({ x: width / 2, y: height / 2 });
//     }
//   }, []);
//   return [dimensions, translate, containerRef];
// };
// export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
//   const [translate, setTranslate] = useState(defaultTranslate);
//   const containerRef = useCallback((containerElem) => {
//     if (containerElem !== null) {
      
//       let r:any = Object.assign({},document.querySelectorAll("text"));

//       const full = [r]
//         .map((div) => div.parentElement.parentElement.outerHTML)
//         .filter((txt) => txt.includes("Eric McDaniel"));

//       const translateWord = full[0].indexOf("translate");
//       const styleWord = full[0].indexOf("style");
//       const coordsString = full[0].substring(translateWord + 10, styleWord - 3);
//       const [x, y] = coordsString.split(",").map((n) => parseInt(n, 10));

//       const { width, height } = containerElem.getBoundingClientRect();
//       console.log(width, height);
//       console.log(x, y);
//       setTranslate({ x: width / 2 - x * 1.5, y: height / 2 - y * 1.5 });
//     }
//   }, []);
//   return [translate, containerRef];
// };
// interface ContainerProps {
//   address: string;
//   a: string;
// }
const getContract :any =   (address: string, a: string,state:any) => {
   let x =   (async () => {
    const pk = state.profile.pk //useSelector((state: RootState) => state.profile.pk)
    const rpc =  state.profile.rpc //useSelector((state: RootState) => state.profile.rpc)
    const file = require("../abi/" + a)
    //console.log("abi ",file)
    console.log("loading contract: ", address)
    const abi = file.abi //JSON.parse(file).output.abi;
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    //console.log(abi)
    //const signer = ethers.Wallet.fromMnemonic(myAccountMnemonic);
    const signer = new ethers.Wallet(pk, provider)

    const nftContractReadonly = new ethers.Contract(address, abi, signer);
    return nftContractReadonly;
  })()
  return  x;
}
 export default getContract