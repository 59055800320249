import { IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonTextarea, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
//import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './DeployItemPage.css';

import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';

import { useHistory, useParams, withRouter } from 'react-router-dom';
import axios from 'axios'

import contract from "./contract.json"
import tokenuri from "./tokenuri.json"


import { ethers } from 'ethers';
import registry from '../abi/registry.json';
import tokenMetadata from '../abi/TokenMetadata.json';
import soulboundJson from '../abi/SoulboundToken.json';
import { refresh } from '../features/counter/profileSlice';
import axiosRetry from "axios-retry"


import Topbar from '../components/TopBar';
import rscvalve from '../abi/RSC.json';

const DeployItemPage: React.FC = (key: any) => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const admin = useSelector((state: RootState) => state.profile.admin)
  const pk = useSelector((state: RootState) => state.profile.pk)
  const profile = useSelector((state: RootState) => state.profile)

  const pubk = useSelector((state: RootState) => state.profile.pubk)
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  //const { name } = useParams<{ name: string; }>();
  const [items, setItems] = useState<any>([]);
  const [metaContract, setContract] = useState(contract);
  const [metaToken, setToken] = useState(tokenuri);

  const history = useHistory();
  const { sku } = useParams<{ sku?: string }>();

  const nameRef = useRef<HTMLIonInputElement>(null);
  const descriptionRef = useRef<HTMLIonInputElement>(null);

  const exterLinkRef = useRef<HTMLIonInputElement>(null);
  const projectIdRef = useRef<HTMLIonInputElement>(null);
  const dispatch = useDispatch()

  const buy = async (i: any) => {
    if (!window.confirm("Buy")) {
      return;
    }
    //throw new Error('Function not implemented.');
    const query = new URLSearchParams({
      platform: 'playstation_network'
    }).toString();
    console.log(i)
    const projectId = '205984';
    const itemSku = i.sku;
    const virtualCurrencySku = 'YOUR_virtual_currency_sku_PARAMETER';
    const resp = await fetch(
      `https://store.xsolla.com/api/v2/project/${projectId}/free/item/${itemSku}`,///virtual/${virtualCurrencySku}?${query}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + stoken
        },
        body: JSON.stringify({
          custom_parameters: {
            array_param: [
              {
                object1: {
                  param: 'https://xsolla.com'
                },
                property: {
                  'int-property': 112,
                  'string-property': 'xsolla'
                }
              },
              {
                object2: {
                  param: 'https://developers.xsolla.com'
                },
                property: {
                  'int-property': 112,
                  'string-property': 'email@example.com'
                }
              }
            ],
            object_param: { key: 'value' },
            bool_param: true,
            int_param: 100,
            null_param: null
          }
        })
      }
    );

    const data = await resp.json();
    console.log(data);
  }
  useEffect(() => {
    if (sku == "new") {
      setItems([{}])
      return;
    } else {
      setItems([])
    }

    console.log("token", btoa(admin.merchant_id + ":" + admin.api))
    //https://axios-http.com/docs/example
    //https://medium.com/swlh/interacting-with-restful-apis-using-typescript-react-hooks-and-axios-part-1-af52920ae3e4
    //let axios = new Axios()
    //axios.get('https://store.xsolla.com/api/v2/project/44056/user/inventory/items',
    //205984
    //axios.get('https://store.xsolla.com/api/v2/project/2059842/user/inventory/items',
    //axios.get('https://store.xsolla.com/api/v2/project/88974/user/inventory/items',
    axios.get('https://store.xsolla.com/api/v2/project/' + window.project_id + '/admin/items/virtual_items/sku/' + sku,
      {
        headers: {
          "authorization": "Basic " + btoa(admin.merchant_id + ":" + admin.api) //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE5NjIyMzQwNDgsImlzcyI6Imh0dHBzOi8vbG9naW4ueHNvbGxhLmNvbSIsImlhdCI6MTU2MjE0NzY0OCwidXNlcm5hbWUiOiJ4c29sbGEiLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IjA2SWF2ZHpDeEVHbm5aMTlpLUc5TmMxVWFfTWFZOXhTR3ZEVEY4OFE3RnMiLCJzdWIiOiJkMzQyZGFkMi05ZDU5LTExZTktYTM4NC00MjAxMGFhODAwM2YiLCJlbWFpbCI6InN1cHBvcnRAeHNvbGxhLmNvbSIsInR5cGUiOiJ4c29sbGFfbG9naW4iLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6ImU2ZGZhYWM2LTc4YTgtMTFlOS05MjQ0LTQyMDEwYWE4MDAwNCIsInB1Ymxpc2hlcl9pZCI6MTU5MjR9.GCrW42OguZbLZTaoixCZgAeNLGH2xCeJHxl8u8Xn2aI"
        }
      }
    )
      .then(function (response: any) {
        // handle success
        console.log(response);
        const arr: any = []
        arr.push(response.data)
        //items.push(response)
        setItems(arr)
        // setState
      })

    return;

  }, [sku])
  const deployItem = (sku: string) => {
    console.log(sku)
    history.push("/page/deploy" + sku)
  }


  const [selectedImageFile, setImageSelectedFile] = useState("");
  const [selectedAssetFile, setAssetSelectedFile] = useState("");
  const [selectedLicneseFile, setLicenseSelectedFile] = useState("");

  const [cidImageFile, setCidImageSelectedFile] = useState("");
  const [cidAssetFile, setSidAssetSelectedFile] = useState("");
  const [cidLicneseFile, setSidLicenseSelectedFile] = useState("");

  const [cidTokenUri, setSidTokenUri] = useState("");
  const [cidContractUri, setSidContractUri] = useState("");

  const changeImageHandler = (event: any) => {
    setImageSelectedFile(event.target.files[0]);
  };
  const changeAssetHandler = (event: any) => {
    setAssetSelectedFile(event.target.files[0]);
  };
  const changeLicenseHandler = (event: any) => {
    setLicenseSelectedFile(event.target.files[0]);
  };
  const JWT = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI0MDFkZjVlMC1mODNhLTQyMDMtOGVjNi00NWJiNmZkZTk4YWEiLCJlbWFpbCI6ImFyc2VuM2RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImM4YWUwNDFkNDMxZGRlMDVhZDllIiwic2NvcGVkS2V5U2VjcmV0IjoiNGY2NGIwMGJkZTY5ZDFkNTVmMDZlYTRiOGQ0OTg5ODgzNjU0YjQ1YzU5Y2NiNDk3OWM3MTQ1NWFmNGE4NTVmZiIsImlhdCI6MTY3MDk2NzQwOH0.CAx-o_c-3O8GWlDp_Ag3BRGzJ6gFuv0OYBtjmZwU5qY`
  const handleImageSubmission = async () => {
    const formData: any = new FormData();

    formData.append('file', selectedImageFile)

    const metadata = JSON.stringify({
      name: 'File name',
    });
    formData.append('pinataMetadata', metadata);

    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);

    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        //maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: JWT
        }
      });
      console.log(res.data);
      setCidImageSelectedFile("ipfs://" + res.data.IpfsHash)
      pinTokenUriJson()
    } catch (error) {
      console.log(error);
    }
  };

  const handleAssetSubmission = async () => {
    const formData: any = new FormData();

    formData.append('file', selectedAssetFile)

    const metadata = JSON.stringify({
      name: 'File name',
    });
    formData.append('pinataMetadata', metadata);

    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);

    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        //maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: JWT
        }
      });
      console.log(res.data);
      setCidImageSelectedFile("ipfs://" + res.data.IpfsHash)
      pinTokenUriJson()
    } catch (error) {
      console.log(error);
    }
  };
  const handleLicenseSubmission = async () => {
    const formData: any = new FormData();

    formData.append('file', selectedLicneseFile)

    const metadata = JSON.stringify({
      name: 'File name',
    });
    formData.append('pinataMetadata', metadata);

    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);

    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        //maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: JWT
        }
      });
      console.log(res.data);
      setSidLicenseSelectedFile("ipfs://" + res.data.IpfsHash)
      pinContractUriJson()
    } catch (error) {
      console.log(error);
    }
  };
  const pinTokenUriJson = async () => {
    var metadata = JSON.stringify({
      "pinataOptions": {
        "cidVersion": 1
      },
      "pinataMetadata": {
        "name": "testing",
        "keyvalues": {
          "customKey": "customValue",
          "customKey2": "customValue2"
        }
      },
      "pinataContent": {
        "somekey": "somevalue3"
      }
    });

    var configMetadata = {
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': JWT
      },
      data: {
        metaToken
      }
    };

    const metadataRes = await axios(configMetadata);

    console.log(metadataRes.data);
    setSidTokenUri("ipfs://" + metadataRes.data.IpfsHash)
    return metadataRes.data.IpfsHash;
  }
  const pinContractUriJson = async () => {


    var contractData = JSON.stringify({
      "pinataOptions": {
        "cidVersion": 1
      },
      "pinataMetadata": {
        "name": "testing",
        "keyvalues": {
          "customKey": "customValue",
          "customKey2": "customValue2e"
        }
      },
      "pinataContent": {
        metaContract
      }
    });

    var configContractMetadata = {
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': JWT
      },
      data: contractData
    };

    const contractRes = await axios(configContractMetadata);

    console.log(contractRes.data);
    setSidContractUri("ipfs://" + contractRes.data.IpfsHash)
    //pinContractUriJson()
    return contractRes.data.IpfsHash;
  }
  const deployCollection = async () => {


    // Deploy the contract to Ethereum test network - Ropsten
    //const provider = ethers.providers.getDefaultProvider('ropsten')
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    // Use your wallet's private key to deploy the contract
    const privateKey = pk
    const wallet = new ethers.Wallet(privateKey, provider)

    // Read the contract artifact, which was generated by Remix
    const metadata: any = tokenMetadata; //JSON.parse(tokenMetadata)

    // Set gas limit and gas price, using the default Ropsten provider
    const price = ethers.utils.formatUnits(await provider.getGasPrice(), 'gwei')
    const options = { gasLimit: 10000000, gasPrice: ethers.utils.parseUnits(price, 'gwei') }

    // Deploy the contract
    const factory = new ethers.ContractFactory(metadata.abi, metadata.data.bytecode.object, wallet)
    const contract = await factory.deploy(options)
    await contract.deployed()
    console.log(`Deployment successful! Contract Address: ${contract.address}`);

    return contract;
    // let uri: string = "ipfs://" + contractCID;

    // console.log(`Deployment successful! Contract Address: ${contract.address}`);
    // await contract.setContractURI(uri);
    // console.log("contractURI ", await contract.contractURI())
    // return contract.address
  }
  const deployAddressbook = async () => {


    // Deploy the contract to Ethereum test network - Ropsten
    //const provider = ethers.providers.getDefaultProvider('ropsten')
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    // Use your wallet's private key to deploy the contract
    const privateKey = pk
    const wallet = new ethers.Wallet(privateKey, provider)

    // Read the contract artifact, which was generated by Remix
    const metadata: any = soulboundJson; //JSON.parse(tokenMetadata)

    // Set gas limit and gas price, using the default Ropsten provider
    const price = ethers.utils.formatUnits(await provider.getGasPrice(), 'gwei')
    const options = { gasLimit: 10000000, gasPrice: ethers.utils.parseUnits(price, 'gwei') }

    // Deploy the contract
    const factory = new ethers.ContractFactory(metadata.abi, metadata.data.bytecode.object, wallet)
    const contract = await factory.deploy(options)
    await contract.deployed()
    // let uri: string = "ipfs://" + contractCID;

    console.log(`deployAddressbook successful! Contract Address: ${contract.address}`);
    // await contract.setContractURI(uri);
    // console.log("contractURI ", await contract.contractURI())
    return contract.address
  }
  const deployRSC = async () => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const wallet = new ethers.Wallet(pk, provider)
    const price = ethers.utils.formatUnits(await provider.getGasPrice(), 'gwei')
    const options = { gasLimit: 10000000, gasPrice: ethers.utils.parseUnits(price, 'gwei') }
    const factory = new ethers.ContractFactory(rscvalve.abi, rscvalve.data.bytecode.object, wallet)
    const contract = await factory.deploy(options)
    await contract.deployed()
    return contract.address
  }
  const uploadToPinata = async (sourceUrl: string) => {
    console.log("sourceUrl", sourceUrl)
    const axiosInstance = axios.create();

    axiosRetry(axiosInstance, { retries: 5 });
    const data: any = new FormData();

    const response = await axiosInstance(sourceUrl, {
      method: "GET",
      responseType: "blob",
    });
    //data.append(`file`, response.data);
    const formData: any = new FormData();

    formData.append('file', response.data)

    const metadata = JSON.stringify({
      name: 'File name',
    });
    formData.append('pinataMetadata', metadata);

    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);


    console.log("response.data", response.data)
    console.log("formData", formData.getAll("file"))
    //return;
    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        //maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data;`,
          'Authorization': JWT
        }
      });
      console.log(res.data);
      return res.data.IpfsHash

    } catch (error) {
      console.log(error)
    }
  };
  const mintLicense = async () => {
    // console.log(exterLinkRef?.current?.value)
    // return;
    //contract.name = "test2"
    let item: any = items[0];
    metaContract.name = (nameRef?.current?.value?.toString() || "")
    metaContract.description = (descriptionRef?.current?.value?.toString() || "")
    metaContract.image = cidImageFile || item.image_url;
    metaContract.external_link = (exterLinkRef?.current?.value?.toString() || "")
    metaContract.project_id = (projectIdRef?.current?.value?.toString() || "")
    metaContract.addressBook = await deployAddressbook();
    metaContract.revenueShare = await deployRSC();


    const c = await deployCollection();

    metaContract.collection = c.address

    const contractCID = await pinContractUriJson()
    console.log("contractCID: ", contractCID)
    await c.setContractURI("ipfs://" + contractCID); //"data:application/json;base64,"
    const collection = c.address;
    metaToken.name = metaContract.name;
    metaToken.description = metaContract.description;
    metaToken.url = metaContract.image;
    metaToken.metaContract = metaContract;

    setContract({ ...metaContract });//Object.assign({}, metaContract))//JSON.parse(JSON.stringify(metaContract)))
    setToken({ ...metaToken })
    //0x908c636cfde8dF8ffff5bB31DbD7F1b29D9b8b81
    const abi = registry.abi;//sbabi.output.abi;
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    const nftContractReadonly = new ethers.Contract("0x19A3f98aBeEc1626161ECC179881D1BbB575CEeF", abi, signer); //registry contract //"0x754B76c3C145546eFE18b632877688aab50b4EA2"
    const result: any = await nftContractReadonly.addToList(exterLinkRef?.current?.value, collection, "", "");//, window.prompt("project id?"), window.prompt("project guid?"))
    //const result:any = await nftContractReadonly.addToList("test",collection,"1234","3232")
    console.log(result);

    let i: any = item;
    let image_path: string = cidImageFile.replace("ipfs://", "https://files.xlapp.link/ipfs/");
    if (image_path.length === 0) {
      image_path = i.image_url
    }

    const ipfsHash = await uploadToPinata(image_path)//
    const metadataIpfsHash = await pinTokenUriJson()//i.name,i.description,ipfsHash

    // setSidTokenUri("ipfs://" + metadataRes.data.IpfsHash)
    const token_abi = tokenMetadata.abi;
    // const provider = new ethers.providers.JsonRpcBatchProvider(rpc);
    // const signer = new ethers.Wallet(pk,provider)
    const nftContract = new ethers.Contract(profile.contractData.metaContract.collection, token_abi, signer);// i.groups[0].external_id, abi, signer);//"0x62F254699A2f657d56BEE9Bf8C90de536576DDD0"
    //console.log(await signer.provider.getCode(profile.contractData.metaContract.collection))
    const r = await nftContract.safeMint(pubk, "ipfs://" + metadataIpfsHash)
    console.log(r)
    dispatch(refresh())
    setTimeout(() => history.push("/page/nfts"), 5000)


    // await nftContractReadonly.burn(i.token_id)
  }

  const updateItemApi = async () => {
    const projectId = 'YOUR_project_id_PARAMETER';
    const itemSku = 'YOUR_item_sku_PARAMETER';
    const resp = await fetch(
      `https://store.xsolla.com/api/v2/project/${projectId}/admin/items/virtual_items/sku/${itemSku}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa('<username>:<password>')
        },
        body: JSON.stringify({
          sku: 'sword',
          name: {
            en: 'Sword',
            de: 'Schwert'
          },
          is_enabled: true,
          is_free: false,
          order: 1,
          description: {
            en: 'A sword is a bladed melee weapon intended for cutting or thrusting that is longer than a knife or dagger, consisting of a long blade attached to a hilt.',
            de: 'Ein Schwert ist eine Nahkampfwaffe mit Klinge, die zum Schneiden oder Stechen bestimmt ist, länger als ein Messer oder Dolch ist und aus einer langen Klinge besteht, die an einem Griff befestigt ist.'
          },
          prices: [
            {
              amount: 100,
              currency: 'USD',
              is_enabled: true,
              is_default: true
            },
            {
              amount: 200,
              currency: 'CZK',
              country_iso: 'CZ',
              is_enabled: false,
              is_default: true
            }
          ],
          vc_prices: [],
          is_show_in_store: true,
          attributes: [
            {
              external_id: 'craft-materials',
              name: { en: 'Craft materials' },
              values: [
                {
                  external_id: 'steel',
                  value: { 'en-US': '5' }
                },
                {
                  external_id: 'leather',
                  value: { 'en-US': '1' }
                }
              ]
            }
          ],
          limits: {
            per_user: 5,
            per_item: 100
          }
        })
      }
    );

    if (resp.status === 204) {
      console.log('success');
    } else {
      const data = await resp.json();
      console.log(data);
    }
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <Topbar />

          {/* <IonTitle>{name}</IonTitle> */}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            {/* <IonTitle size="large">{name}</IonTitle> */}
          </IonToolbar>
        </IonHeader>
        {/* {stoken} */}
        {/* <br/>
        {items.length} */}
        {/* Deploy Item {JSON.stringify(admin)} */}
        {/* <div>{sku}</div> */}



        {items.map((i: any, k: any) => {

          return (<div key={k}>
            {/* <IonList>
              <IonItem> */}
            {/* <IonLabel>Start:<IonDatetime /></IonLabel>
                <IonLabel>End:<IonDatetime /></IonLabel> */}
            {/* <IonLabel>Preview: */}
            <div className='flex-container-nft' >

              <div className='card' >
                <IonLabel>Preview:</IonLabel>
                <img className='nft-img' alt={i?.name?.en} src={cidImageFile.replace("ipfs://", "https://files.xlapp.link/ipfs/") || i?.image_url} />
                <br />
                <br />
                {i.name?.en}
                <br />

                {/* <IonInput className="custom-textarea" placeholder='uri' value={cidAssetFile} /> */}
                <input type="file" onChange={changeImageHandler} />
                <button onClick={handleImageSubmission}>Submit</button>
                {/* {i.token_id} */}
              </div>

              {/* </IonLabel> */}
              <div className='card' >
                <IonLabel>Start:</IonLabel>
                <IonDatetime />
                <br />

              </div>
              <div className='card' >
                <IonLabel>End:</IonLabel>
                <IonDatetime />
                <br />

              </div>
            </div>
            {/* </IonItem>
            </IonList> */}
            {/* <IonLabel> */}
            {/* <pre>
              {JSON.stringify(i, null, 2)}
            </pre> */}
            <IonLabel>Name:</IonLabel>
            <IonInput ref={nameRef} value={i?.name?.en} className="custom-textarea" placeholder='name' />

            <IonLabel>Description:</IonLabel>
            <IonInput ref={descriptionRef} value={i?.description?.en} className="custom-textarea" placeholder='description' />

            <IonLabel>External Link:</IonLabel>
            <IonInput ref={exterLinkRef} className="custom-textarea" placeholder='url' />
            <IonLabel>Project ID:</IonLabel>
            <IonInput ref={projectIdRef} className="custom-textarea" placeholder='id' />


            <IonLabel>Youtube Video:</IonLabel>
            <IonInput className="custom-textarea" placeholder='url' />
            <IonLabel>Asset:</IonLabel>
            <IonList>
              <IonItem >
                
                <IonInput className="custom-textarea" placeholder='uri' value={cidAssetFile} />
                <div slot="end"> 
                <input type="file" onChange={changeAssetHandler} />
                <button onClick={handleAssetSubmission}>Submit</button>
                </div>
              </IonItem>
            </IonList>
            License Agreement:
            <IonList>
              <IonItem>
                <IonInput className="custom-textarea" placeholder='uri' value={cidLicneseFile} />
                <div slot="end">
                  <input type="file" onChange={changeLicenseHandler} />
                  <button onClick={handleLicenseSubmission}>Submit</button>
                </div>
              </IonItem>
            </IonList>




            {/* <IonLabel>License Agreement:</IonLabel> */}



            <IonLabel>Token URI:</IonLabel>
            <IonInput className="custom-textarea" placeholder='uri' value={cidTokenUri} />

            <IonLabel>Contract URI:</IonLabel>
            <IonInput className="custom-textarea" placeholder='uri' value={cidContractUri} />

            {/* <IonLabel>Revenue Share Contract (Soulbound License Owner):</IonLabel>
            <IonInput className="custom-textarea" placeholder='address' value={"0xA97F337c39cccE66adfeCB2BF99C1DdC54C2D721"} /> */}

            <IonLabel>Soulbound Licensee:</IonLabel>
            <IonInput className="custom-textarea" placeholder='address' value={pubk} />

            <IonLabel>Seller Fee %:</IonLabel>
            <IonInput className="custom-textarea" placeholder='fee' />

            <IonLabel>Royalty Fee %:</IonLabel>
            <IonInput className="custom-textarea" placeholder='fee' />
            Usage Guidelines:
            <IonTextarea className="custom-textarea" placeholder="Type something here"></IonTextarea>
            Asset Details:
            <IonTextarea className="custom-textarea" placeholder="Type something here"></IonTextarea>
            {/* <IonList>
              <IonItem>
                
              </IonItem>
            </IonList> */}
            Features:
            <IonList>
              <IonItem>
                <IonLabel>Login</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Dashboard</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Coins</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Nfts</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Mint</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Drops</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>License</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>BulkUpload</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>RevShare</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>DigID</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Analytics</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
            </IonList>
            Parties:
            <IonList>
              <IonItem>
                <IonLabel>Affiliates</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Distributors</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Thirdparty Contractors</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Customers or end users of the licensee's product or services; or employees</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
            </IonList>
            Rights:
            <IonList>
              <IonItem>
                <IonLabel>Reproduce the works</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Distribute the works</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Display the works publically</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Create derivate works</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Rent works</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Event Tickets</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
            </IonList>
            Geography:
            <IonList>
              <IonItem>
                <IonLabel>North America</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>South America</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Europe</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Asia</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
            </IonList>
            Platforms:
            <IonList>
              <IonItem>
                <IonLabel>Print</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Radio</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Motion Picture</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Television</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Internet</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Metaverse</IonLabel>
                <IonToggle slot="end"></IonToggle>
              </IonItem>
            </IonList>
            <IonButton onClick={mintLicense}>Mint License Token</IonButton>

            <pre>
              metadata.json
              <br />
              {JSON.stringify({
                name: i?.name?.en,
                description: i?.description?.en,
                url: cidImageFile || i.image_url,
                animation_url: cidAssetFile,
                external_url: "",
                background_color: "",
                youtube_url: ""

              }, null, 2)}
            </pre>
            <pre>
              contract.json
              <br />
              {JSON.stringify(metaContract, null, 2)}
              {/* {JSON.stringify({
                "name": i.name.en,
                "description": i.description.en,
                "image": i.image_url,
                "external_link": cidLicneseFile,
                "seller_fee_basis_points": 100, // Indicates a 1% seller fee.
                "fee_recipient": "0xA97F337c39cccE66adfeCB2BF99C1DdC54C2D721" // Where seller fees will be paid to.
              }, null, 2)} */}
            </pre>


            {/* <IonButton onClick={updateItemApi} >Request Quote</IonButton> */}
          </div>
          )
          // return <img   key={x} width={200} height={200} alt={i.sku}onClick={()=>buy(i)} src={i.image_url}/> 
        })
        }


        {/* <ExploreContainer name={name} /> */}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(DeployItemPage);



/*
https://eips.ethereum.org/EIPS/eip-5218
https://eips.ethereum.org/EIPS/eip-5635
https://eips.ethereum.org/EIPS/eip-2981
https://eips.ethereum.org/EIPS/eip-5554
*/