import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ethers } from 'ethers'

export interface CounterState {
  value: number
  provider:any
}
// export interface TokenState {
//   value: string
// }
const initialState: CounterState = {
  value: 0,
  provider: new ethers.providers.JsonRpcProvider("https://xla-1674265553-1.sagarpc.xyz")//"https://rpc.xlapp.link")
}
export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    // token: (state, action: PayloadAction<string>) => {
    //   state.value = action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default counterSlice.reducer